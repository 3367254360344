/** @jsx jsx */
import { jsx } from "theme-ui";
import PropTypes from "prop-types";
import Header from "./regions/header/header";
import Footer from "./regions/footer/footer";
import { SkipNavLink, SkipNavContent } from "@reach/skip-nav";
import "@reach/skip-nav/styles.css"; //this will show/hide the link on focus
import React from "react";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import { Helmet } from "react-helmet";

const Layout = ({ children, type }) => {
  return (
    <div className={type}>
      <SkipNavLink />
      <Header type={type} />
      <SkipNavContent />
      <main>{children}</main>
      <Footer />
      <div
        key={`body`}
        id="___gatsby"
        dangerouslySetInnerHTML={{
          __html: `<script type="text/javascript" id="zsiqchat">var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq || {widgetcode: "410a5e3cfa41c9d7653566ed4e757f97160b309d48512d9a8da4adc5d307540f2fec1073e3015a53bf225d6bc12a0f9c", values:{},ready:function(){}};var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;s.src="https://salesiq.zoho.com/widget";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);</script>`,
        }}
      />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
