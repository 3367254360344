/** @jsx jsx */
import { jsx } from "theme-ui";
import Image from "./image";
import InlineImage from "./inlineImage";
import Embed from "./embed";
import CategoryTopicDisplay from "./categoryTopicDisplay";
import Hero from "./hero";
import List from "./list";
import TextImage from "./textImage";
import TextLeftTextRight from "./textLeftTextRight";
import TextBlock from "./textBlock";
import NewsBlock from "./newsBlock";
import DocReference from "./docReference";
import Contact from "./contact";
import MediaContact from "./mediaContact";
import EnterpriseContact from "./enterpriseContact";
import Quote from "./quote";
import FaqBlock from "./faqBlock";
import TextBlockWithPaddding from "./textBlockWithPadding";
import SectionBackgroundImage from "./sectionBackgroundImage";
import VideoModal from "./videoModal";
import RecentArticlesBlock from "./recentArticlesBlock";
import HeroBackgroundImage from "./heroBackgroundImage";

const myPortableTextComponents = {
  types: {
    image: Image,
    inlineImage: InlineImage,
    embed: Embed,
    categoryTopicDisplay: CategoryTopicDisplay,
    hero: Hero,
    list: List,
    textImage: TextImage,
    textLeftTextRight: TextLeftTextRight,
    textBlock: TextBlock,
    newsBlock: NewsBlock,
    docReference: DocReference,
    contact: Contact,
    mediaContact: MediaContact,
    enterpriseContact: EnterpriseContact,
    quote: Quote,
    faqBlock: FaqBlock,
    textBlockWithPadding: TextBlockWithPaddding,
    sectionBackgroundImage: SectionBackgroundImage,
    recentArticlesBlock: RecentArticlesBlock,
    heroBackgroundImage: HeroBackgroundImage,
  },
  block: (props) => {
    switch (props.node.style) {
      case "video-play":
        return (
          <div>
            <VideoModal props={props}></VideoModal>
          </div>
        );
      case "cta":
        return (
          <div
            className="cta"
            sx={{
              a: {
                variant: "buttons.primarySolid",
                marginTop: "5px",
                display: "inline-block",
                fontWeight: "600",
              },
            }}
          >
            {props.children}
          </div>
        );
      case "cta-light":
        return (
          <div
            className="cta-light"
            sx={{
              a: {
                variant: "buttons.primaryLight",
                marginTop: "5px",
                display: "inline-block",
                fontWeight: "600",
              },
            }}
          >
            {console.log(props)}
            <a href={props.children[0].props.value.href}>
              <span
                sx={{
                  position: "relative",
                }}
              >
                {props.children[0].props.text}
              </span>
            </a>
          </div>
        );
      case "sub-title":
        return <div className="sub-title">{props.children}</div>;
      case "h1":
        return <h1>{props.children}</h1>;
      case "h2":
        return <h2>{props.children}</h2>;

      case "h3":
        return <h3>{props.children}</h3>;

      case "h4":
        return <h4>{props.children}</h4>;
      case "h5":
        return <h5>{props.children}</h5>;
      case "h6":
        return <h6>{props.children}</h6>;
      case "blockquote":
        return <blockquote>{props.children}</blockquote>;
      case "normal":
        return <p>{props.children}</p>;
      default:
        return <p>{props.children}</p>;
    }
  },
  marks: {
    file: ({ children, value }) => {
      return (
        <a target="_blank" href={value.asset.url}>
          {children}
        </a>
      );
    },
    document_reference: ({ children, value }) => {
      if (value.document_reference) {
        var type = value.document_reference.post_type;
        if (type === "article") {
          var typePath = "/articles/";
        }
        if (type === "blog_post") {
          var typePath = "/blog/";
        }
        var href = typePath + value.document_reference.slug.current;
        var ariaLabel = value.document_reference.ariaLabel
          ? value.document_reference.ariaLabel
          : "";
      }
      return (
        <a target="_blank" href={href} aria-label={ariaLabel}>
          {children}test
        </a>
      );
    },
    link: ({ children, value }) => {
      if (value.href) {
        var ariaLabel = value.ariaLabel ? value.ariaLabel : "";
        const rel = !value.href.startsWith("/")
          ? "noreferrer noopener"
          : undefined;
        return (
          <a href={value.href} rel={rel} aria-label={ariaLabel}>
            {children}
          </a>
        );
      } else {
        return <span>{children}</span>;
      }
    },
  },
};

export default myPortableTextComponents;
