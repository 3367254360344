/** @jsx jsx */
import { jsx } from "theme-ui";
import PortableTextComponent from "./portableTextComponents";
import { PortableText } from "@portabletext/react";
import { useRef } from "react";
import plus from "../../images/faq-minus.svg";
import minus from "../../images/faq-plus.svg";

const Faq = ({ faq, onToggle, active }) => {
  const contentEl = useRef();

  return (
    <div
      className={`accordion_item ${active ? "active" : ""}`}
      aria-haspopup="true"
      aria-expanded={active}
      sx={{
        backgroundColor: active ? "white" : "primary",
        border: "thin solid",
        borderColor: "primary",
        marginBottom: "15px",
        padding: active ? "30px 40px 10px 40px" : "15px 40px",
        borderRadius: "40px",
        overflow: "hidden",
        transition: "all ease 0.5s",

        ":hover": {
          bg: active ? "white" : "primaryDark",
        },
      }}
    >
      <div
        onClick={onToggle}
        sx={{
          cursor: "pointer",
          height: "100%",
        }}
      >
        <button
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            cursor: "pointer",
            color: active ? "primary" : "white",
            textDecoration: "none",
            background: "none",
            border: "none",
            padding: "0",
            font: "inherit",
            cursor: "pointer",
            outline: "inherit",
            width: "100%",
            pointerEvents: "none",
          }}
          onFocus={onToggle}
        >
          <div
            sx={{
              maxWidth: "460px",
              fontWeight: "semibold",
              marginBottom: active ? "10px" : "0px",
            }}
          >
            {faq.question && faq.question}
          </div>
          <div
            sx={{
              position: "relative",
              overflow: "hidden",
              display: "flex",
              alignItems: "center",
              height: "25px",

              ".plus": {
                transition: "opacity ease 0.5s",
                opacity: active ? "0" : "1",
                position: active ? "absolute" : "relative",
              },
              ".minus": {
                transition: "opacity ease 0.5s",
                opacity: active ? "1" : "0",
                position: active ? "relative" : "absolute",
                top: "-3px",
              },
            }}
          >
            <img className="minus" src={minus} />
            <img className="plus" src={plus} />
          </div>
        </button>
      </div>
      <div
        ref={contentEl}
        sx={{
          height: active ? contentEl.current.scrollHeight : "0px",
          transition: "height ease 0.5s",
          overflow: "hidden",
          p: {
            marginTop: "0px",
          },
        }}
      >
        {faq.answer && (
          <PortableText value={faq.answer} components={PortableTextComponent} />
        )}
      </div>
    </div>
  );
};

export default Faq;
