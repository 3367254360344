/** @jsx jsx */
import { jsx } from "theme-ui";
import { StaticQuery, graphql } from "gatsby";
import { Link } from "gatsby";
import Burger from "./burger";
import { useState } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { AnchorLink } from "gatsby-plugin-anchor-links";

const Menu = () => {
  const [subMenuState, setSubMenuState] = useState([false, false]);
  const [menuState, setMenuState] = useState(false);
  const toggleMenu = () => {
    let newState = !menuState;
    setMenuState(newState);
  };
  return (
    <StaticQuery
      query={graphql`
        query MenuQuery {
          submenu: sanityPageDefinition(slug: { current: { eq: "topics" } }) {
            _rawEntities(resolveReferences: { maxDepth: 10 })
          }
          facebook: file(name: { eq: "facebook-white" }) {
            publicURL
          }
          linkedin: file(name: { eq: "linkedin-white" }) {
            publicURL
          }
          instagram: file(name: { eq: "instagram-white" }) {
            publicURL
          }
          youtube: file(name: { eq: "youtube-white" }) {
            publicURL
          }
          mobileLogo: file(name: { eq: "logodark" }) {
            publicURL
          }
          logodark: file(name: { eq: "logodark" }) {
            childImageSharp {
              gatsbyImageData
            }
          }
          logolight: file(name: { eq: "logolight" }) {
            childImageSharp {
              gatsbyImageData
            }
          }
          siteSettings: sanitySiteSettings {
            primaryColor
            logo {
              asset {
                gatsbyImageData
              }
            }
          }
        }
      `}
      render={(data) => (
        <div
          sx={{
            padding: "0px",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            marginLeft: "0px",
            backdropFilter: [
              menuState && "blur(10px)",
              menuState && "blur(10px)",
              "none",
            ],
            backgroundColor: [
              menuState && "rgba(0,0,0,0.6)",
              menuState && "rgba(0,0,0,0.6)",
              "transparent",
            ],
          }}
          className={menuState && "mobile-menu-open"}
        >
          <Link
            sx={{
              width: ["175px"],
              display: "flex",
            }}
            to="/"
          >
            <GatsbyImage
              image={data.logodark.childImageSharp.gatsbyImageData}
              className="logo-dark"
            />
            <GatsbyImage
              image={data.logolight.childImageSharp.gatsbyImageData}
              className="logo-light"
            />
          </Link>
          <Burger clickMe={(e) => toggleMenu()} open={menuState} />
          <div
            sx={{
              width: ["100%", "100%", "calc(100% - 175px)"],
              display: [
                menuState ? "block" : "none",
                menuState ? "block" : "none",
                "flex",
              ],
              flexDirection: ["column"],
              alignItems: "flex-end",
              justifyContent: "center",
            }}
          >
            <div
              sx={{
                display: "flex",
                justifyContent: ["center", "center", "space-between"],
                padding: ["10px 0px", "10px 0px", "0px"],
                alignItems: "center",
                flexWrap: ["wrap", "wrap", "nowrap"],
                marginBottom: "20px",
                ".header-email": {
                  color: "white",
                  textDecoration: "none",
                  fontSize: "13px",
                  borderRight: "thin solid white",
                  marginRight: "15px",
                  paddingRight: "15px",
                  ".header-phone": {
                    color: "white",
                    textDecoration: "none",
                    fontSize: "13px",
                    borderRight: "thin solid white",
                    marginRight: "15px",
                    paddingRight: "15px",
                    borderRight: ["0px", "0px", "thin solid white"],
                  },
                  "&:hover": {
                    textDecoration: "underline",
                  },
                },
                ".header-phone": {
                  color: "white",
                  textDecoration: "none",
                  fontSize: "13px",
                  borderRight: "thin solid white",
                  marginRight: ["0px", "0px", "15px"],
                  paddingRight: ["0px", "0px", "15px"],
                  borderRight: ["0px", "0px", "thin solid white"],
                  "&:hover": {
                    textDecoration: "underline",
                  },
                },
                ".header-social-container": {
                  display: "flex",
                  alignItems: "center",
                  width: ["100%", "100%", "auto"],
                  justifyContent: "center",
                  marginTop: ["20px", "20px", "0px"],
                  a: {
                    marginLeft: "10px",
                    display: "flex",
                    "&:nth-child(1)": {
                      marginLeft: "0px",
                    },
                  },
                },
              }}
            >
              <a className="header-email" href="mailto:info@allstarcnc.com">
                info@allstarcnc.com
              </a>
              <a className="header-phone" href="tel:800-878-7827">
                800-878-7827
              </a>
              <div className="header-social-container">
                <a
                  target="_blank"
                  href="https://www.facebook.com/allstarcncgasketing"
                >
                  <img src={data.facebook.publicURL} />
                </a>
                <a
                  target="_blank"
                  href="https://www.linkedin.com/company/68653054/admin/"
                >
                  <img src={data.linkedin.publicURL} />
                </a>
                <a target="_blank" href="https://www.instagram.com/allstarcnc/">
                  <img src={data.instagram.publicURL} />
                </a>
                <a
                  target="_blank"
                  href="https://www.youtube.com/channel/UCISRsctnqrFQy671JtczMgg"
                >
                  <img src={data.youtube.publicURL} />
                </a>
              </div>
            </div>
            <nav
              aria-label="All Star CNC"
              sx={{
                display: ["block"],
              }}
            >
              <ul
                role="menubar"
                aria-label="All Star CNC"
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  flexDirection: ["column", "column", "row"],
                  margin: "0px",
                  padding: ["20px 0px", "20px 0px", "0px"],
                  li: {
                    listStyle: "none",
                    lineHeight: "1.5",
                    marginLeft: ["0px", "0px", "24px"],
                    padding: ["10px 0px", "10px 0px", "0px"],
                    width: ["100%", "100%", "auto"],
                    textAlign: "center",
                    a: {
                      color: "white",
                      textDecoration: "none",
                      fontSize: ["22px", "22px", "17px"],
                      fontWeight: "600",
                    },
                  },
                }}
              >
                <li
                  className="menuitem"
                  role="none"
                  sx={{
                    position: "relative",
                  }}
                >
                  <Link to="/">Home</Link>
                </li>
                <li
                  className="menuitem"
                  role="none"
                  sx={{
                    position: "relative",
                  }}
                >
                  <AnchorLink to="/events" title="Events"></AnchorLink>
                </li>
                <li
                  className="menuitem"
                  role="none"
                  sx={{
                    position: "relative",
                    "&:hover > div": {
                      display: "block",
                      visibility: "visible",
                      opacity: 1,
                    },
                    ul: {
                      li: {
                        padding: "0px",
                        margin: "0px",
                        a: {
                          color: ["#ffffff", "grayMed", "grayMed"],
                          textDecoration: "none",
                          display: "block",
                          padding: ["5px 0px", "10px 0px"],
                          borderBottom: ["none", "none", "thin solid white"],
                          fontSize: ["12px", "12px", "inherit"],
                        },
                        "&:nth-child(1)": {
                          a: {
                            borderTop: ["none", "none", "thin solid white"],
                          },
                        },
                      },
                    },
                  }}
                >
                  <Link to="/products">Products</Link>
                  <div
                    sx={{
                      display: ["block", "block", "none"],
                      position: ["relative", "relative", "absolute"],
                      paddingTop: ["0px", "0px", "20px"],
                      width: ["100%", "100%", "300px"],
                      left: ["auto", "auto", "-110px"],
                    }}
                  >
                    <ul
                      className="submenu"
                      sx={{
                        padding: ["10px 0px", "10px 0px", "20px"],
                        margin: "0px",
                        backdropFilter: ["none", "none", "blur(10px)"],
                        backgroundColor: [
                          "transparent",
                          "transparent",
                          "rgba(0,0,0,0.6)",
                        ],
                      }}
                    >
                      <li className="submenu-link">
                        <Link to="/grid-gasketing">Grid Gasket</Link>
                      </li>
                      <li className="submenu-link">
                        <Link to="/in-board-gasketing">In Board Gasket</Link>
                      </li>
                      <li className="submenu-link">
                        <Link to="/on-board-gasketing">On-Board Gasket</Link>
                      </li>
                      <li className="submenu-link">
                        <Link to="/cover-gasketing">Cover Gasket</Link>
                      </li>
                      <li className="submenu-link">
                        <Link to="/tile-gasket-tm">Tile Gasket</Link>
                      </li>
                      <li className="submenu-link">
                        <Link to="/tooling-parts-and-other-gasketing-accessories">
                          Tooling and Bits
                        </Link>
                      </li>
                      <li className="submenu-link">
                        <Link to="/vacuum-clamping-gasket-kits">
                          Vacuum Clamping Gasket Kits
                        </Link>
                      </li>
                      <li className="submenu-link">
                        <Link to="/shop-sabre-vacuum-system-upgrade-kit">
                          Shop Sabre Vacuum Upgrade Kits
                        </Link>
                      </li>
                      <li className="submenu-link">
                        <Link to="/parts-and-other-gasketing-accessories">
                          Parts and Other Gasketing Accessories
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>
                <li
                  className="menuitem"
                  role="none"
                  sx={{
                    position: "relative",
                  }}
                >
                  <Link to="/resources">Resources</Link>
                </li>
                <li
                  className="menuitem"
                  role="none"
                  sx={{
                    position: "relative",
                  }}
                >
                  <Link to="/blog">Stories</Link>
                </li>
                <li
                  className="menuitem"
                  role="none"
                  sx={{
                    position: "relative",
                  }}
                >
                  <Link to="/request-quote">Request a Quote</Link>
                </li>
                <li
                  className="menuitem"
                  role="none"
                  sx={{
                    position: "relative",
                  }}
                >
                  <a
                    sx={{
                      color: "#190A32 !important",
                      textDecoration: "none",
                      display: "inline-block",
                      fontSize: "0.875rem",
                      borderRadius: "1.5rem",
                      bg: "white",
                      padding: [".2rem .8rem", ".5rem 1.2rem"],
                      textDecoration: "none",
                      ":hover": {
                        color: "white !important",
                        background:
                          "linear-gradient(to right, #0b5d57 0%, #00a998 98%, #00a998 100%);",
                        textDecoration: "none !important",
                      },
                    }}
                    href="https://store.allstarcnc.com/"
                    target="_blank"
                  >
                    Shop Now
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      )}
    />
  );
};

export default Menu;
