/** @jsx jsx */
import { jsx } from "theme-ui";
import Modal from "react-modal";
import React, { useState, useEffect } from "react";
import LiteYouTubeEmbed from "react-lite-youtube-embed";
import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css";
const VideoModal = ({ props }) => {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      zIndex: "10",
      width: "50%",
      padding: "20px 20px 20px 20px",
      postition: "relative",
      overflow: "visible",
    },
  };
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }
  return (
    <div>
      <div
        onClick={openModal}
        sx={{
          width: "109px",
          height: "109px",
          borderRadius: "50%",
          background: "rgba(255, 255, 255, 0.3)",
          textAlign: "center",
          display: "inline-block",
          lineHeight: "109px",
          animationName: "hvr-pulse",
          animationDuration: "1s",
          animationTimingFunction: "linear",
          animationIterationCount: "infinite",
          verticalAlign: "middle",
          transform: "perspective(1px) translateZ(0)",
          boxShadow: "0 0 1px transparent",
          cursor: "pointer",
        }}
      >
        <div
          sx={{
            display: "inline-block",
            verticalAlign: "middle",
            width: "84px",
            height: "84px",
            color: "#fff",
            fontSize: "20px",
            lineHeight: "84px",
            borderRadius: "50%",
            background: "primary",
          }}
        >
          <div
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <div
              sx={{
                width: "0",
                height: "0",
                borderStyle: "solid",
                borderWidth: "10px 0 10px 20px",
                borderColor: "transparent transparent transparent #ffffff",
              }}
            ></div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Allstar Youtube"
      >
        <div
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <div
            sx={{
              fontSize: "38px",
              cursor: "pointer",
              fontWeight: "bold",
              position: "absolute",
              zIndex: "999",
              top: "-30px",
              right: "-12px",
              textShadow: "1px 1px 2px #000000",
            }}
            onClick={closeModal}
          >
            X
          </div>
        </div>
        <LiteYouTubeEmbed
          id={props.children[0].props.value.href}
          params="autoplay"
        />
      </Modal>
    </div>
  );
};

export default VideoModal;
