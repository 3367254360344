/** @jsx jsx */
import { jsx } from "theme-ui";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import sanityConfig from "../../sanityConfig";
import PortableTextComponent from "../serializers/portableTextComponents";
import { PortableText } from "@portabletext/react";
import Container from "../container";
import BackgroundImage from "gatsby-background-image";
import { convertToBgImage } from "gbimage-bridge";
import { getGatsbyImageData } from "gatsby-source-sanity";

const HeroBackgroundImage = ({ value }) => {
  const gatsbyHeroImageData = getGatsbyImageData(
    value.image,
    { maxWidth: 100 },
    sanityConfig
  );
  const bgImage = convertToBgImage(gatsbyHeroImageData);
  return (
    <div id={value.id ? value.id : ""}>
      <BackgroundImage Tag="section" {...bgImage} preserveStackingContext>
        <Container>
          <div
            sx={{
              position: "relative",
              zIndex: "2",
              padding: "180px 0px 100px 0px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h1 sx={{ color: "white" }}>{value.title}</h1>
          </div>
        </Container>
        <div
          sx={{
            zIndex: "1",
            position: "absolute",
            top: "0",
            left: "0",
            height: "100%",
            width: "100%",
            opacity: "0.7",
            background:
              "linear-gradient(to top, #0b5d57 0%, #000000 98%, #000000 100%)",
          }}
        ></div>
      </BackgroundImage>
    </div>
  );
};

export default HeroBackgroundImage;
