/** @jsx jsx */
import { jsx } from "theme-ui";
import PortableTextComponent from "./portableTextComponents";
import { PortableText } from "@portabletext/react";
import Container from "../container";
const SectionBackgroundImage = ({ value }) => {
  console.log(value);
  return (
    <div
      className="section-background-image"
      id={value.id ? value.id : ""}
      sx={{
        color: "textGray",
        fontSize: "body",
        padding: "100px 0px",
        color: "white",
        position: "relative",
        zIndex: "1",
        background: value.backgroundImage
          ? "URL(" +
            value.backgroundImage.asset.url +
            ") center center no-repeat fixed"
          : "",
      }}
    >
      <div
        sx={{
          zIndex: "1",
          position: "absolute",
          top: "0",
          left: "0",
          height: "100%",
          width: "100%",
          opacity: "0.7",
          background:
            "linear-gradient(to right, #0b5d57 0%, #000000 98%, #000000 100%)",
        }}
      ></div>
      <div
        sx={{
          position: "relative",
          zIndex: "2",
        }}
      >
        <Container>
          <PortableText value={value.text} components={PortableTextComponent} />
        </Container>
      </div>
    </div>
  );
};

export default SectionBackgroundImage;
