/** @jsx jsx */
import { jsx } from "theme-ui";
import { GatsbyImage } from "gatsby-plugin-image";
import sanityConfig from "../../sanityConfig";
import { getGatsbyImageData } from "gatsby-source-sanity";
import PortableTextComponent from "./portableTextComponents";
import { PortableText } from "@portabletext/react";
import Container from "../container";

const TextLeftTextRight = ({ value }) => {
  return (
    <div
      sx={{
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        flexDirection: ["column", "column", "row"],
        "p:empty ": {
          display: "none",
        },
        ".text-left": {
          width: ["100%", "50%", "50%"],
          // padding: "20px 40px 20px 40px",
          boxSizing: "border-box",
        },
        ".text-right": {
          width: ["100%", "50%", "50%"],
          padding: "20px 40px 20px 40px",
          boxSizing: "border-box",
        },
      }}
      className="text-text-container"
    >
      <div className="text-left">
        <div
          className="text-left-inner"
          sx={{
            color: "textGray",
            fontSize: "body",
            "h1,h2,h3,h4,h5,h6": {
              color: "primary",
            },
          }}
        >
          <PortableText
            value={value.text_left}
            components={PortableTextComponent}
          />
        </div>
      </div>
      <div
        className="text-right"
        sx={{
          color: "textGray",
          fontSize: "body",
          "h1,h2,h3,h4,h5,h6": {
            color: "primary",
          },
        }}
      >
        <PortableText
          value={value.text_right}
          components={PortableTextComponent}
        />
      </div>
    </div>
  );
};

export default TextLeftTextRight;
