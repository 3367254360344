/** @jsx jsx */
import { jsx } from "theme-ui";
import Menu from "./menu";
import { useState, useEffect } from "react";

const Header = () => {
  const [menuScroll, setMenuScroll] = useState(false);
  useEffect(() => {
    // changeLogo();
    // adding the event when scroll change Logo
    window.addEventListener("scroll", changeBackground);
  });
  const changeBackground = () => {
    if (window.scrollY >= 66) {
      setMenuScroll(true);
    } else {
      setMenuScroll(false);
    }
  };
  return (
    <header
      sx={{
        width: "100%",
        position: "fixed",
        zIndex: "3",
        padding: ["0px", "0px", "0px 2.5rem"],
        boxSizing: "border-box",
        transition: "background-color .5s linear",
        "&.menu-scrolled": {
          backdropFilter: "blur(10px)",
          backgroundColor: "rgba(0,0,0,0.6)",
        },
        ".mobile-menu-open": {
          overflow: ["scroll !important", "scroll !important", "auto"],
          maxHeight: "100vh",
          height: ["100%", "100%", "auto"],
        },
        '[aria-current="page"]': {
          // color: "black",
          textDecoration: "underline",
        },
        ".menuitem": {
          a: {
            "&:hover": {
              color: menuScroll ? "primary" : "white",
              textDecoration: "underline",
            },
          },
        },
      }}
      className={menuScroll ? "menu-scrolled" : "menu"}
    >
      <Menu></Menu>
    </header>
  );
};

export default Header;
