import React from "react";
import TeamMember from "../entities/teamMember/teamMember";
import ProductTeaser from "../entities/product/productTeaser";
const DocReference = ({ value }) => {
  if (value.documentReference._type === "teamMember") {
    return <TeamMember entity={value.documentReference} />;
  }
  if (value.documentReference._type === "product") {
    return <ProductTeaser entity={value.documentReference} />;
  }
  return null;
};

export default DocReference;
