/** @jsx jsx */
import { jsx } from "theme-ui";
import PortableTextComponent from "./portableTextComponents";
import { PortableText } from "@portabletext/react";
import Container from "../container";

const TextBlockWithPadding = ({ value }) => {
  return (
    <div
      className="text-block"
      id={value.id ? value.id : ""}
      sx={{
        color: "textGray",
        fontSize: "body",
        padding: "75px 0px",
        "h1,h2,h3,h4,h5,h6": {
          color: "primary",
        },
      }}
    >
      <Container>
        <PortableText value={value.text} components={PortableTextComponent} />
      </Container>
    </div>
  );
};

export default TextBlockWithPadding;
